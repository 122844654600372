import React, { useCallback, useEffect } from 'react';
import { useApolloClient } from '@apollo/client';
import {
  DialogProvider,
  Loader,
  LoadingBarProvider,
  useDialog,
  useSnackbar,
} from '@fdha/web-ui-library';
import { AuthStatus, useAuthStatus } from '@fdha/web-auth';
import { Private, Public } from '@routes';
import { Box, Theme, useMediaQuery } from '@mui/material';
import { isMobile } from '@utils';
import { NotificationsProvider } from '@hooks';
import {
  ActivityDetectorProvider,
  AnalyticsProvider,
  FeatureFlagsProvider,
  Hub,
  WebSocketProvider,
} from '@fdha/common-hooks';
import {
  PlatformType,
  useGetApiKeysLazyQuery,
  useGetProfileLazyQuery,
  UserSessionInfoInput,
  useUpdateUserSessionInfoMutation,
} from '@fdha/graphql-api-patient';
import { useNavigate } from 'react-router';

const hubs: Hub[] = [
  { basePath: '/', name: 'Home' },
  { basePath: '/activities', name: 'Activities' },
  { basePath: '/courses', name: 'Learning' },
  { basePath: '/learning', name: 'Learning' },
  { basePath: '/main-goal', name: 'Surveys' },
  { basePath: '/meals', name: 'Meals' },
  { basePath: '/profile', name: 'Profile Settings' },
  { basePath: '/surveys', name: 'Surveys' },
];

function App() {
  const authStatus = useAuthStatus();
  const client = useApolloClient();
  const { closeDialog } = useDialog();
  const navigate = useNavigate();
  const { showSnackbarV2 } = useSnackbar();
  const isMobileSize = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm')
  );

  const [updateUserSessionInfo] = useUpdateUserSessionInfoMutation();

  const isMobileBrowser = isMobile();
  const unsupported = isMobileSize && isMobileBrowser;

  const onSignOut = useCallback(async () => {
    showSnackbarV2({
      severity: 'warning',
      message: 'Session expired. Please log in again.',
      closeOnClickOutside: true,
      closeAutomatically: false,
      i18nKey: 'common:snackbar.sessionExpired',
    });
    closeDialog();
    navigate('/', { replace: true });
  }, [showSnackbarV2, closeDialog, navigate]);

  const updateUserSession = async (
    patientId: string,
    timezone?: string | null
  ) => {
    const patientTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const props: UserSessionInfoInput = {
      platform: PlatformType.Web,
      action: 'activateSession',
    };

    if (patientId && patientTimezone !== timezone) {
      props.timezone = patientTimezone;
    }

    await updateUserSessionInfo({
      variables: {
        props,
      },
    });
  };

  const [getApiKeys] = useGetApiKeysLazyQuery();
  const [getProfile] = useGetProfileLazyQuery({
    onCompleted: (result) =>
      updateUserSession(result.me.id, result.me.timezone),
  });

  useEffect(() => {
    if (authStatus === AuthStatus.SIGNED_IN) {
      getApiKeys();
      getProfile();
    }
  }, [authStatus, getApiKeys, getProfile]);

  useEffect(() => {
    Intercom('shutdown');

    const clearCache = async () => {
      try {
        await client.cache.reset();
      } catch (e) {
        console.log('Error cleaning cache', e);
      }
    };

    if (authStatus === AuthStatus.SIGNED_OUT) {
      clearCache();
    }
  }, [authStatus, client]);

  useEffect(() => {
    if (unsupported) {
      navigate('/download', { replace: true });
    }
  }, [unsupported, navigate]);

  if (authStatus === AuthStatus.LOADING) {
    return <Loader />;
  }

  return (
    <>
      <Box>
        {authStatus === AuthStatus.SIGNED_IN ? (
          <FeatureFlagsProvider>
            <WebSocketProvider>
              <AnalyticsProvider hubs={hubs}>
                <DialogProvider>
                  <NotificationsProvider>
                    <ActivityDetectorProvider onSignOut={onSignOut}>
                      <LoadingBarProvider>
                        <Private />
                      </LoadingBarProvider>
                    </ActivityDetectorProvider>
                  </NotificationsProvider>
                </DialogProvider>
              </AnalyticsProvider>
            </WebSocketProvider>
          </FeatureFlagsProvider>
        ) : (
          <Public />
        )}
      </Box>
    </>
  );
}

export default App;
